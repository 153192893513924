import { getStreamingToken } from '../../Http';

const SET_ROOM_SETTINGS = 'SET_ROOM_SETTINGS';
const SET_PARTICIPANT_REJECTED = 'SET_PARTICIPANT_REJECTED';
const SET_PARTICIPANT_KICKED = 'SET_PARTICIPANT_KICKED';
const RESET_STORE = 'RESET_STORE';
const SET_PARTICIPANT_CONTSTRAINTS = 'SET_PARTICIPANT_CONTSTRAINTS';
const SET_GETTING_TOKEN = 'SET_GETTING_TOKEN';

const LS_MY_NAME_KEY = 'SCN_MY_NAME'

let stateFromLS = {};

try {
  stateFromLS = JSON.parse(localStorage.getItem(LS_MY_NAME_KEY)) || {};
} catch (e) {/* */}

const initialState = {
  token: '',
  roomName: stateFromLS.roomName || '',
  userName: stateFromLS.userName || '',
  sessionId: '',
  isAdmin: false,
  rejected: false,
  kicked: false,
  audioOnly: false,
  constraints: {
    audio: true,
    video: true,
  },
  viewer: false,
  gettingToken: false,
};

function login(userName, sessionId, isAdmin = false) {
  return async (dispatch, getState) => {
    dispatch(setGettingToken(true));

    try {
      const response = await getStreamingToken(sessionId)

      const {streaming_token} = response.data;

      dispatch(setRoomSettings({ token: streaming_token, userName, sessionId, isAdmin, rejected: false, gettingToken: false }));
    } catch (e) {
      console.error('Error: ', e);
    }

    dispatch(setGettingToken(false));
  }
}

function resetStore() {
  return {
    type: RESET_STORE,
    payload: {
      token: '',
      username: '',
      sessionId: '',
      isAdmin: '',
    }
  }
}

function setRoomSettings(payload) {
  return {
    type: SET_ROOM_SETTINGS,
    payload,
  }
}

function setParticipantRejected(payload) {
  return {
    type: SET_PARTICIPANT_REJECTED,
    payload,
  }
}

function setParticipantKicked(payload) {
  return {
    type: SET_PARTICIPANT_KICKED,
    payload,
  }
}

function setParticipantConstraints(payload) {
  return {
    type: SET_PARTICIPANT_CONTSTRAINTS,
    payload,
  }
}

function setGettingToken(payload) {
  return {
    type: SET_GETTING_TOKEN,
    payload,
  }
}

export {
  login,
  resetStore,
  setParticipantRejected,
  setParticipantKicked,
  setRoomSettings,
  setParticipantConstraints,
  setGettingToken,
}

const ACTION_HANDLERS = {
  [SET_ROOM_SETTINGS]: (state, action) => {
    const result = { ...state, ...action.payload };
    localStorage.setItem(LS_MY_NAME_KEY, JSON.stringify(result));
    return result;
  },

  [SET_PARTICIPANT_REJECTED]: (state, action) => {
    return { ...state, rejected: action.payload, kicked: false }
  },
  [SET_PARTICIPANT_KICKED]: (state, action) => {
    return { ...state, kicked: action.payload, rejected: false }
  },
  [RESET_STORE]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [SET_PARTICIPANT_CONTSTRAINTS]: (state, action) => {
    return { ...state, constraints: action.payload, }
  },
  [SET_GETTING_TOKEN]: (state, action) => {
    return { ...state, gettingToken: action.payload, }
  },
};

export default function ParticipantReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
