import React from "react";
import {Tooltip, Fab} from '@mui/material';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import './Controls.css';

const FastControls = ({videoEnabled, toggleCamera, audioEnabled, setAudioEnabled}) => {
    return (
        <div className="camera-controls-fast">
            <Tooltip title={<div className='tooltip-box'>{audioEnabled ? 'Turn microphone off' : 'Turn microphone on'}</div>} placement='top'>
                <Fab
                    size="medium"
                    color="primary"
                    aria-label="toggle video"
                    sx={{backgroundColor: 'rgba(25, 118, 210, .3)'}}
                    onClick={() => {setAudioEnabled(!audioEnabled)}}
                >
                    {audioEnabled ? <MicIcon fontSize="large" /> : <MicOffIcon fontSize="large" />}
                </Fab>
            </Tooltip>
            <Tooltip title={<div className='tooltip-box'>{videoEnabled ? 'Turn camera off' : 'Turn camera on'}</div>} placement='top'>
                <Fab
                    size="medium"
                    color="primary"
                    aria-label="toggle video"
                    sx={{backgroundColor: 'rgba(25, 118, 210, .3)'}}
                    onClick={toggleCamera}
                >
                    {videoEnabled ? <VideoCameraFrontIcon fontSize="large" /> : <MotionPhotosOffIcon fontSize="large" />}
                </Fab>
            </Tooltip>
        </div>
    );
}

export default FastControls;
