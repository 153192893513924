import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetStore } from '../../Store/Reducers/ParticipantReducer';
import Preview from '../Preview/Preview';

const Login = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStore())
  }, [dispatch])

  return (
    <Preview />
  );
}

export default withRouter(Login)
