import { createTheme } from '@mui/material';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                    root: {
                    borderRadius: 32,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    paddingLeft: 6,
                    paddingRight: 6,
                },
            },
        },
      },
});

export default theme;