const disabled = true;

export default [
    {
        path: '/aharon',
        pswd: 'Aharon1$$Y',
        name: 'Aharon',
    },
    {
        path: '/jonathan',
        pswd: 'Jonny2$$Y',
        name: 'Jonathan',
    },
    {
        path: '/paul',
        pswd: 'Paul3$$Y',
        name: 'Paul',
    },
    {
        path: '/natalia',
        pswd: 'Natalia4$$Y',
        name: 'Natalia',
    },
    {
        path: '/dev',
        pswd: 'Dev5$$Y',
        name: 'Dev',
    },
    {
        path: '/nico',
        pswd: 'Nico1$$Y',
        name: 'Nico',
    },
    {
        path: '/nma',
        pswd: 'nma9$$Y',
        name: 'Nma',
    },
    {
        path: '/miguel',
        pswd: 'Miguel1$$Y',
        name: 'Miguel',
    },
    {
        path: '/shah',
        pswd: 'Shah$$Y',
        name: 'Shah',
        disabled,
    },
    {
        path: '/clients',
        pswd: 'Clients$$Y',
        name: 'Clients',
    },
    {
        path: '/roma',
        pswd: '1111',
        name: 'Roma',
    },
    {
        path: '/anton',
        pswd: '222',
        name: 'Anton',
    },
    {
        path: '/vikt',
        pswd: 'v',
        name: 'Vikt',
    },
    {
        path: '/arcadyan-demo-1',
        pswd: 'arcadyan-1111',
        name: 'arcadyan-demo-1',
    },
    {
        path: '/arcadyan-demo-2',
        pswd: 'arcadyan-2222',
        name: 'arcadyan-demo-2',
    },
];
