import React from 'react';
import { Box } from '@mui/material';

import { Card, Button } from '@mui/material';

const Arcadyan = ({ videoPendingState, videoEnabled, submitPreviewData, disabled }) => {
  const btnSize = {
    xs: '360px',
    md: '600px',
    xl: '896px',
  };

  return (
    <Box sx={{
      backgroundColor: '#fafafa',
      width: '100%',
      height: '100%',
    }}>
      <Box sx={{
        backgroundColor: '#fafafa',
        background: 'url("https://www.arcadyan.com/static/images/home/global-map.0f5061eb08dc.jpg")',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundPosition: 'right',
      }}>
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            position: 'absolute',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            left: {
              xs: `calc(50% - ${btnSize.xs} / 2 - 16px)`,
              md: `calc(50% - ${btnSize.md} - 16px)`,
              xl: `calc(50% - ${btnSize.xl} - 16px)`,
            },
            top: {
              xs: 32,
              md: `calc(50% - ${btnSize.md} / 2 - 16px)`,
              xl: `calc(50% - ${btnSize.xl} / 2 - 16px)`,
            },
          }}
        >
          <Button
            sx={{
              width: btnSize,
              height: btnSize,
              margin: 1,
              background: 'url(https://www.arcadyan.com/media/solutions/idea/2.4-1.1_STB.png)',
              backgroundSize: '100%',
              fontSize: '64px',
              position: 'relative',
              alignItems: 'flex-end',
            }}
            tabIndex={1}
            onClick={e => {submitPreviewData('arcadyan-demo-1')}}
            disabled={disabled}
          >
            <img
              src="https://www.arcadyan.com/static/images/logo.baf03ceed4ab.png"
              style={{position: 'absolute', top: '48px'}}
              alt="arcadyan-logo-1"
            />
            Demo 1
          </Button>
          <Button
            sx={{
              width: btnSize,
              height: btnSize,
              margin: 1,
              background: 'url(https://www.arcadyan.com/media/solutions/idea/2.4-1.4_STB.png)',
              backgroundSize: '100%',
              fontSize: '64px',
              position: 'relative',
              alignItems: 'flex-end',
            }}
            tabIndex={2}
            onClick={e => {submitPreviewData('arcadyan-demo-2')}}
            disabled={disabled}
          >
            <img
              src="https://www.arcadyan.com/static/images/logo.baf03ceed4ab.png"
              style={{position: 'absolute', top: '48px'}}
              alt="arcadyan-logo-2"
            />
            Demo 2
          </Button>
        </Box>


        <Card
          sx={{
            position: 'fixed',
            left: {
              xs: 'calc(50% - 128px / 2)',
              md: 'calc(50% - 384px / 2)',
            },
            bottom: 16,
            width: {
              xs: '128px',
              md: '384px',
            },
          }}>
          <div className='p-camera'>
            {videoPendingState && videoEnabled !== false && (
              <div className='p-camera-placeholder'>
                Preparing camera...
              </div>
            )}

            {videoEnabled === null && (
              <div className='p-camera-placeholder'>
                Getting configuration...
              </div>
            )}

            {videoEnabled === false && (
              <div className='p-camera-placeholder'>
                Your camera is turned off
              </div>
            )}
            <video id='video-preview' muted={true} className='p-block' />
          </div>
        </Card>
      </Box>
    </Box>
  )
};

export default Arcadyan
