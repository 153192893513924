import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useLocation } from 'react-router';
import { Box, Button, TextField } from '@mui/material';
import qs from 'query-string';

import { login, setRoomSettings } from '../../Store/Reducers/ParticipantReducer';
import passwords from '../../constants/pswds';
import './Login.css'

const LoginCard = ({ history, handleJoin, handleJoinAsAViewer, disabled=false, viewerOnly=false }) => {
  const {
    userName,
    isAdmin: isAdminInitial,
  } = useSelector(state => state.participant);

  const [participantName, setParticipantName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  let room = qs.parse(location.search).room || location.pathname;
  room = room.replace('/', '');

  useEffect(() => {
    setParticipantName(userName);
    setIsAdmin(isAdminInitial || false);
  }, [userName, isAdminInitial]);

  const joinToConference = (e, type="default", cb) => {
    if (!participantName) {
      return;
    }

    dispatch(setRoomSettings({viewer: type === 'viewer'}));
    e.preventDefault();

    setLoading(true);
    if (!isAdmin && passwordMapper[participantName] && passwordMapper[participantName].replace('/', '') === room) {
      setIsAdmin(true);
      setParticipantName('');
      setLoading(false);
      return
    }
    dispatch(login(participantName, room, isAdmin))
    setLoading(false);

    if (cb) {
      return cb(e, {room, type});
    }

    history.push(`/session?room=${room}`);
  };

  const handleJoinBtn = e => {
    if (handleJoin) {
      return joinToConference(e, 'default', handleJoin);
    }

    return joinToConference(e);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      return joinToConference(e, 'default', () => {/*pass*/});
    }
  }

  const handleJoinAsAViewerBtn = e => {
    if (handleJoinAsAViewer) {
      return joinToConference(e, 'viewer', handleJoinAsAViewer);
    }

    return joinToConference(e, 'viewer');
  };

  const passwordMapper = passwords.reduce((acc, item) => {
    acc[item.pswd] = item.path;
    return acc;
  }, {});

  const onChangeParticipantName = (e) => {
    setParticipantName(e.target.value);
  };

  return (
    <Box
      component="form"
      className='login-form'
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Your name"
        variant="outlined"
        className='login-form-item'
        size='small'
        onChange={onChangeParticipantName}
        value={isAdmin ? '*********' : participantName}
        disabled={isAdmin}
        inputProps={{
          onKeyUp: handleKeyUp,
        }}
      />
      <TextField
        label="Your name"
        variant="outlined"
        className={`login-form-item ${isAdmin ? '' : 'hide'}`}
        size='small'
        onChange={onChangeParticipantName}
        value={participantName}
      />
      <div className='login-form-btns'>
        <Button
          variant="contained"
          color="primary"
          className='login-form-btn'
          onClick={handleJoinBtn}
          disabled={loading || disabled || !participantName || viewerOnly}
        >
          JOIN NOW
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className='login-form-btn'
          onClick={handleJoinAsAViewerBtn}
          disabled={loading || disabled || !participantName || isAdmin}
        >
          JOIN AS A VIEWER
        </Button>
      </div>
    </Box>
  );
}

export default withRouter(LoginCard);
