import React from 'react';
import {Route, Switch,Redirect} from 'react-router-dom';
import { toast } from 'react-toastify';
import {withRouter} from 'react-router';

import initSentry from '../../utils/sentry';
import Conf from '../Conf/Conf';
import Login from '../Login/Login';
import Session from '../Session/Session';
import Preview from '../Preview/Preview';
import ThankYou from '../ThankYou/ThankYou';
import LoginRoutes from '../../constants/pswds';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

initSentry();

toast.configure()

const Arcadyan = () => <Preview isArcadyan />;

function App({history}) {
    return (
      <Switch>
        <Route path='/preview' component={Preview} exact />
        <Route path='/conf' component={Conf} exact />
        {LoginRoutes.map(
          item => item.disabled ?
            null :
            <Route path={item.path} component={Login} exact key={item.path} />
          )
        }
        <Route path='/preview' component={Preview} exact />
        <Route path='/arcadyan' component={Arcadyan} exact />
        <Route path='/session' component={Session} exact />
        <Route path='/thank-you' component={ThankYou} exact />
        <Route path='/' exact render={() => (
          <Redirect to='/conf' />
        )} />
      </Switch>
  );
}

export default withRouter(App);
