import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Card, CardContent, TextField, Container, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../MUI/Theme';
import confs from '../../constants/pswds';
import throttle from '../../utils/throttle';
import { setRoomSettings } from '../../Store/Reducers/ParticipantReducer';

const Conf = () => {
    const dispatch = useDispatch();
    const { roomName } = useSelector(state => state.participant);
    const rooms = confs.map(item => item.path);

    const roomChangeHandler = throttle((elem) => {
        dispatch(setRoomSettings({roomName: elem.target.value}));
    }, 300);

    const handleGoBtn = () => {
        if (rooms.includes(`/${roomName}`)) {
            window.location.href = `preview?room=${roomName}`;
        }
    }

    return (
        <ThemeProvider theme={theme} >
            <div className='app-bg' />
            <div className='powered-by-container'>
                <div className='powered-by-text'>Powered by</div>
                <div className='logo-top'>
                </div>
            </div>
            <Container>
                <Card
                    sx={{ maxWidth: 380, margin: 'auto' }}
                >
                    <CardContent>
                        <TextField
                            id="room"
                            label="Enter room name"
                            variant="outlined"
                            size='small'
                            sx={{ width: '100%', marginTop: 2 }}
                            onChange={roomChangeHandler}
                            value={roomName}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className='login-form-btn'
                            sx={{ width: '100%', marginTop: 2 }}
                            onClick={handleGoBtn}
                        >
                            Next
                        </Button>
                    </CardContent>
                </Card>
            </Container>
        </ThemeProvider>
    );
}

export default Conf;
