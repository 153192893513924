import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './Menu.css';

const Menu = (props) => {
	const { visible, hideModal, tabs, } = props

	const [activeTab, setActiveTab] = useState(null)

	const useStyles = makeStyles((theme) => ({
		button: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			height: 55,
			borderRadius: 0,
			color: '#fff',
			fontSize: 16,
			fontFamily: 'Gilroy-Regular',
			textTransform: 'none',
		},
		active: {
			backgroundColor: '#FFFFFF11'
		},
		firstButton: {
			borderBottom: '1px solid rgba(255, 255, 255, .5)',
		},
	}));

	const classes = useStyles();

	const openTab = (action, index) => {
		action()
		closeUnusedTabs(index)
		setActiveTab(index)
	}

	const closeUnusedTabs = (index) => {
		tabs.forEach((tab, key) => {
			if (key !== index) {
				tab.close()
			}
		})
	}

	const closeMenu = () => {
		hideModal()
		setActiveTab(null)
	}

	return (
		<>
			<div
				className={`m-wrapper ${visible ? 'opened' : ''}`}
				onClick={closeMenu}
			/>
			<div className={`m-content ${visible ? 'opened' : ''}`}>
				{tabs.map((item, index) => (
					<Button
						className={clsx(classes.button, activeTab === index ? classes.active : null, index === 0 ? classes.firstButton : null)}
						variant="outlined"
						onClick={() => openTab(item.action, index)}
						key={index}
						tabIndex={0}
					>
						{item.icon}
						{item.title}
					</Button>
				))}
			</div>
		</>
	)
};

export default Menu;
