import React from 'react';
import { useLocation } from 'react-router';
import { ThemeProvider, Container, Box } from '@mui/material';

import { Button } from '@mui/material';
import qs from 'query-string';

import theme from '../MUI/Theme';

const ThankYou = () => {
  const location = useLocation();
  const room = qs.parse(location.search).room;

  const backToPreview = () => {
    window.location.href = `/${room}`;
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        height: '100%',
        paddingTop: 4,
        paddingBottom: 20,
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            maxHeight: '100px',
          }}>
            <img src="/sceenic-black.svg" width="200px" alt="sceenic logo" />
            &nbsp;
            <Box sx={{
              fontSize: '36px',
              paddingTop: '7px',
              color: '#082B44',
              display: {
                xs: 'none',
                md: 'flex',
              }
            }}>
              Conference
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={backToPreview}
            sx={{
              height: '48px',
              fontSize: '15px',
              padding: '0 32px',
            }}
          >
            BACK TO PREVIEW
          </Button>
        </Box>
      </Container>
        
    </ThemeProvider>
  )
};

export default ThankYou;
