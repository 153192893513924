import React, {useEffect, useRef, useState} from 'react';
import './Timer.css'

const Timer = () => {
    const timerIdRef = useRef(0);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const secondsRef = useRef(seconds);
    const minutesRef = useRef(minutes);

    useEffect(() => {
        timerIdRef.current = setInterval(() => {
            const newSeconds = secondsRef.current + 1;

            if(newSeconds === 60) {
                setSeconds(0)
                setMinutes(minutesRef.current + 1)
            } else {
                setSeconds(newSeconds)
            }

        }, 1000)

        return () => {
            clearTimeout(timerIdRef.current)
        }
    }, [])

    useEffect(() => {
        secondsRef.current = seconds;
    }, [seconds]);

    useEffect(() => {
        minutesRef.current = minutes;
    }, [minutes])

    return (<div className='timer'>
        {`${minutes < 10 ? ('0' + minutes) : minutes}:${seconds < 10 ? ('0'+ seconds) : seconds}`}
    </div>)
}

export default Timer