import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

function initSentry() {
    const dsn = process.env.REACT_APP_SENTRY_DSN;

    if (dsn) {
        Sentry.init({
            dsn,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }
};

export default initSentry;