export const qualityConstants = {
  bad: 'bad',
  good: 'good',
  excellent: 'excellent',
};

export const MediaTypes = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO'
};

export const MediaStates = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED'
};
