import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
      width: 55,
      height: 55,
      minWidth: 'auto',
      padding: 0,
      margin: 5,
      border: '1px solid #FFFFFF',
      borderRadius: 100,

      "&:hover": {
        backgroundColor: "#FFFFFF44"
      },
    },
    actionButton: {
      width: 150,
      height: 50,
      padding: 0,
      margin: 5,
      color: '#FFFFFF',
      border: '1px solid #FFF',

      "&:hover": {
        transition: '0.2s',
        backgroundColor: "#FFFFFF11",
      },
    },
    actionSubmit: {
      color: '#082B44',
      backgroundColor: "#FFFFFF",

      "&:hover": {
        transition: '0.2s',
        backgroundColor: "#FFF",
        opacity: 0.7,
      },
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      width: '100%',
      height: 50,
      color: '#CCC',
      fontSize: 16,
      fontFamily: 'Gilroy-Regular',

      '&:before': {
        borderColor: '#082B44'
      },
      '&:after': {
        borderColor: '#CCC'
      }
    },
    formcontrol: {
      width: '100%',
      height: 55,
    },
  }));